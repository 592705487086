@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.container {
  height: 100vh;
  overflow: hidden;
}

/* .header {
  margin-left: 200px;
} */

.trigger:hover {
  color: #1890ff;
}

.carouselShow {
  position: absolute;
  bottom: 40px;
  width: 250px;
  /* transition: all .7s; */
  transition: all .1s ease-in;


}

.hideCarousel {
  position: absolute;
  bottom: 40px;
  width: 0px !important;
  /* transition: all .7s; */
  transition: all .4s ease-out;
}

.logo {
  height: 64px;
  /* margin: 16px; */
  background-color: white;
  /* background: rgba(255, 255, 255, 0.3); */
  color: #556AD2;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  margin-left: 24px;
  align-items: center;
  /* position: sticky;
  top: 0;
  z-index: 1; */
}

.wrapperMainClass {
  /* height: 80% !important; */
  width: 100% !important;
  /* background-color: aqua; */
  margin-bottom: 20px !important;
}

.wrapperContentClass {
  /* height: 80% !important; */
  width: 100% !important;
  /* background-color: red !important; */
}

.tools {
  display: flex;
  flex-direction: column;
  width: 25px;
  position: absolute;
  right: 25px;
  top: 10px;
  z-index: 1;


}

.tools button {
  margin: 2px;
  /* width: 25px;
  height: 25px; */

}

.doc-body figure {
  width: 100% !important;
  margin-left: 0px !important;

}

.doc-body figure table {
  width: 100% !important;
  margin-left: 0px !important;

}

/* .doc-body h2 {
  margin-left: -35px;
} */

/* .doc-body h1 {
  margin-left: -35px;
} */

/* .doc-body h3 {
  margin-left: -15px;
} */

.doc-body p {
  margin-left: 0px !important;
}

.doc-body img {
  width: 100%;
}